<template>
  <div></div>
</template>

<script>
import router from "@/router";

export default {
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // 如果 localStorage 有 AND_LIFF_REDIRECT 就轉去那個網址
      if (localStorage.getItem('AND_LIFF_REDIRECT')) {
        const url = localStorage.getItem('AND_LIFF_REDIRECT');

        console.debug("[AND LIFF REDIRECT] Redirect to", url);

        localStorage.removeItem('AND_LIFF_REDIRECT');
        location.href = url;
        return;
      }

      const orgCode = this.$route.params.orgCode

      let params = new URL(location.href).searchParams;

      if (params.get('liff.state')) {
        console.log("有拿到 liff state", params.get('liff.state'));
        router.push('/' + orgCode + '/liff/proj/abbott-and' + params.get('liff.state'));
      }
    }
  }
}
</script>
